<template>
  <div class="height_100">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="待接单" name="first">
        <div class="flex">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
          >
            <el-form-item label="产品类型" prop="region" required>
              <el-select v-model="ruleForm.region" placeholder="请选择所属分类">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>

          <div class="flex marl_30">
            <div
              style="
                width: 100px;
                line-height: 40px;
                position: relative;
                font-size: 14px;
              "
            >
              关键词搜索
            </div>
            <div class="flex" style="border: 1px solid #cccc;">
              <input
              @keyup.enter="getserach"
                type="text"
                v-model="search"
                placeholder="请输入内容"
                style="
                  width: 200px;
                  height: 40px;
                  border: 1px solid #cccc;
                  border-radius: 5px;
                  padding: 10px;
                  border: none;
                  outline:none;
                "
              />
              <!-- <el-input placeholder="请输入内容"></el-input> -->
              <i
                @click="getserach"
                class="el-icon-search"
                style="font-size: 20px; line-height: 40px;margin-right: 10px;"
              ></i>
            </div>
          </div>
        </div>

        <el-table :data="TableData" style="width: 100%">
          <el-table-column
            prop="ID"
            label="ID"
            min-width="100"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="productname"
            label="产品名称"
            width="135"
            align="center"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="productype"
            label="产品类型"
            min-width="100"
          ></el-table-column>
          <el-table-column
            prop="pattern"
            label="服务模式"
            min-width="100"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="spend"
            label="费用"
            min-width="100"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="enterprise_name"
            label="用户名称"
            min-width="100"
            align="center"
          >
          </el-table-column>

          <el-table-column
            prop="enterprise_mobile"
            label="联系方式"
            min-width="100"
            align="center"
          >
          </el-table-column>

          <el-table-column
            prop="name"
            label="服务机构名称"
            min-width="100"
            align="center"
          >
          </el-table-column>

          <el-table-column
            prop="phone"
            label="服务机构电话"
            min-width="100"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="currency"
            label="服务币"
            min-width="100"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="create_time"
            label="下单时间"
            min-width="100"
            align="center"
          >
          </el-table-column>

          <el-table-column
            prop="content"
            label="产品详情"
            min-width="100"
            align="center"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                style="color: #c065e7"
                @click="lookcontent(scope.row)"
                >查看产品详情</el-button
              >
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            min-width="100"
            align="center"
          >
            <template>
              <el-button type="text" size="small" style="color: #ff6618"
                >无</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="工作中" name="second">
        <div class="flex">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
          >
            <el-form-item label="产品类型" prop="region" required>
              <el-select v-model="ruleForm.region" placeholder="请选择所属分类">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div class="flex marl_30">
            <div
              style="
                width: 100px;
                line-height: 40px;
                position: relative;
                font-size: 14px;
              "
            >
              关键词搜索
            </div>
            <!-- <div class="flex">
              <input
                type="text"
                v-model="search"
                placeholder="请输入内容"
                style="
                  width: 200px;
                  height: 40px;
                  border: 1px solid #cccc;
                  border-radius: 5px;
                  padding: 10px;
                "
              />
              <i
                @click="getserach"
                class="el-icon-search"
                style="font-size: 30px; line-height: 40px"
              ></i>
            </div> -->
            <div class="flex" style="border: 1px solid #cccc;">
              <input
              @keyup.enter="getserach"
                type="text"
                v-model="search"
                placeholder="请输入内容"
                style="
                  width: 200px;
                  height: 40px;
                  border: 1px solid #cccc;
                  border-radius: 5px;
                  padding: 10px;
                  border: none;
                  outline:none;
                "
              />
              <!-- <el-input placeholder="请输入内容"></el-input> -->
              <i
                @click="getserach"
                class="el-icon-search"
                style="font-size: 20px; line-height: 40px;margin-right: 10px;"
              ></i>
            </div>
          </div>
        </div>

        <el-table :data="TableData" style="width: 100%">
          <el-table-column
            prop="ID"
            label="ID"
            min-width="100"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="productname"
            label="产品名称"
            width="135"
            align="center"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="productype"
            label="产品类型"
            min-width="100"
          ></el-table-column>
          <el-table-column
            prop="pattern"
            label="服务模式"
            min-width="100"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="spend"
            label="费用"
            min-width="100"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="enterprise_name"
            label="用户名称"
            min-width="100"
            align="center"
          >
          </el-table-column>

          <el-table-column
            prop="enterprise_mobile"
            label="联系方式"
            min-width="100"
            align="center"
          >
          </el-table-column>

          <el-table-column
            prop="name"
            label="服务机构名称"
            min-width="100"
            align="center"
          >
          </el-table-column>

          <el-table-column
            prop="phone"
            label="服务机构电话"
            min-width="100"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="currency"
            label="服务币"
            min-width="100"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="create_time"
            label="下单时间"
            min-width="100"
            align="center"
          >
          </el-table-column>

          <el-table-column
            prop="content"
            label="产品详情"
            min-width="100"
            align="center"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                style="color: #c065e7"
                @click="lookcontent(scope.row)"
                >查看产品详情</el-button
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="content"
            label="操作"
            min-width="100"
            align="center"
          >
            <template>
              <el-button type="text" size="small" style="color: #ff6618"
                >无</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="待验收" name="third">
        <div class="flex">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
          >
            <el-form-item label="产品类型" prop="region" required>
              <el-select v-model="ruleForm.region" placeholder="请选择所属分类">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div class="flex marl_30">
            <div
              style="
                width: 100px;
                line-height: 40px;
                position: relative;
                font-size: 14px;
              "
            >
              关键词搜索
            </div>
            <!-- <div class="flex">
              <input
                type="text"
                v-model="search"
                placeholder="请输入内容"
                style="
                  width: 200px;
                  height: 40px;
                  border: 1px solid #cccc;
                  border-radius: 5px;
                  padding: 10px;
                "
              />
              <i
                @click="getserach"
                class="el-icon-search"
                style="font-size: 30px; line-height: 40px"
              ></i>
            </div> -->
            <div class="flex" style="border: 1px solid #cccc;">
              <input
              @keyup.enter="getserach"
                type="text"
                v-model="search"
                placeholder="请输入内容"
                style="
                  width: 200px;
                  height: 40px;
                  border: 1px solid #cccc;
                  border-radius: 5px;
                  padding: 10px;
                  border: none;
                  outline:none;
                "
              />
              <!-- <el-input placeholder="请输入内容"></el-input> -->
              <i
                @click="getserach"
                class="el-icon-search"
                style="font-size: 20px; line-height: 40px;margin-right: 10px;"
              ></i>
            </div>
          </div>
        </div>

        <el-table :data="TableData" style="width: 100%">
          <el-table-column
            prop="ID"
            label="ID"
            min-width="100"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="productname"
            label="产品名称"
            width="135"
            align="center"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="productype"
            label="产品类型"
            min-width="100"
          ></el-table-column>
          <el-table-column
            prop="pattern"
            label="服务模式"
            min-width="100"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="spend"
            label="费用"
            min-width="100"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="enterprise_name"
            label="用户名称"
            min-width="100"
            align="center"
          >
          </el-table-column>

          <el-table-column
            prop="enterprise_mobile"
            label="联系方式"
            min-width="100"
            align="center"
          >
          </el-table-column>

          <el-table-column
            prop="name"
            label="服务机构名称"
            min-width="100"
            align="center"
          >
          </el-table-column>

          <el-table-column
            prop="phone"
            label="服务机构电话"
            min-width="100"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="currency"
            label="服务币"
            min-width="100"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="create_time"
            label="下单时间"
            min-width="100"
            align="center"
          >
          </el-table-column>

          <el-table-column
            prop="content"
            label="产品详情"
            min-width="100"
            align="center"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                style="color: #c065e7"
                @click="lookcontent(scope.row)"
                >查看产品详情</el-button
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="content"
            label="操作"
            min-width="100"
            align="center"
          >
            <template>
              <el-button type="text" size="small" style="color: #ff6618"
                >无</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="已完成" name="five">
        <div class="flex">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
          >
            <el-form-item label="产品类型" prop="region" required>
              <el-select v-model="ruleForm.region" placeholder="请选择所属分类">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div class="flex marl_30">
            <div
              style="
                width: 100px;
                line-height: 40px;
                position: relative;
                font-size: 14px;
              "
            >
              关键词搜索
            </div>
            <!-- <div class="flex">
              <input
                type="text"
                v-model="search"
                placeholder="请输入内容"
                style="
                  width: 200px;
                  height: 40px;
                  border: 1px solid #cccc;
                  border-radius: 5px;
                  padding: 10px;
                "
              />
              <i
                @click="getserach"
                class="el-icon-search"
                style="font-size: 30px; line-height: 40px"
              ></i>
            </div> -->
            <div class="flex" style="border: 1px solid #cccc;">
              <input
              @keyup.enter="getserach"
                type="text"
                v-model="search"
                placeholder="请输入内容"
                style="
                  width: 200px;
                  height: 40px;
                  border: 1px solid #cccc;
                  border-radius: 5px;
                  padding: 10px;
                  border: none;
                  outline:none;
                "
              />
              <!-- <el-input placeholder="请输入内容"></el-input> -->
              <i
                @click="getserach"
                class="el-icon-search"
                style="font-size: 20px; line-height: 40px;margin-right: 10px;"
              ></i>
            </div>
          </div>
        </div>

        <el-table :data="TableData" style="width: 100%">
          <el-table-column
            prop="ID"
            label="ID"
            min-width="100"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="productname"
            label="产品名称"
            width="135"
            align="center"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="productype"
            label="产品类型"
            min-width="100"
          ></el-table-column>
          <el-table-column
            prop="pattern"
            label="服务模式"
            min-width="100"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="spend"
            label="费用"
            min-width="100"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="enterprise_name"
            label="用户名称"
            min-width="100"
            align="center"
          >
          </el-table-column>

          <el-table-column
            prop="enterprise_mobile"
            label="联系方式"
            min-width="100"
            align="center"
          >
          </el-table-column>

          <el-table-column
            prop="name"
            label="服务机构名称"
            min-width="100"
            align="center"
          >
          </el-table-column>

          <el-table-column
            prop="phone"
            label="服务机构电话"
            min-width="100"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="currency"
            label="服务币"
            min-width="100"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="create_time"
            label="下单时间"
            min-width="100"
            align="center"
          >
          </el-table-column>

          <el-table-column
            prop="content"
            label="产品详情"
            min-width="100"
            align="center"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                style="color: #c065e7"
                @click="lookcontent(scope.row)"
                >查看产品详情</el-button
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="content"
            label="操作"
            min-width="100"
            align="center"
          >
            <template>
              <el-button type="text" size="small" style="color: #ff6618"
                >无</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>

      <el-tab-pane label="已取消" name="six">
        <div class="flex">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
          >
            <el-form-item label="产品类型" prop="region" required>
              <el-select v-model="ruleForm.region" placeholder="请选择所属分类">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
           <div class="flex marl_30">
            <div
              style="
                width: 100px;
                line-height: 40px;
                position: relative;
                font-size: 14px;
              "
            >
              关键词搜索
            </div>
           <!-- <div class="flex">
              <input
                type="text"
                v-model="search"
                placeholder="请输入内容"
                style="
                  width: 200px;
                  height: 40px;
                  border: 1px solid #cccc;
                  border-radius: 5px;
                  padding: 10px;
                "
              />
              <i
                @click="getserach"
                class="el-icon-search"
                style="font-size: 30px; line-height: 40px"
              ></i>
            </div>
          </div> -->
          <div class="flex" style="border: 1px solid #cccc;">
              <input
              @keyup.enter="getserach"
                type="text"
                v-model="search"
                placeholder="请输入内容"
                style="
                  width: 200px;
                  height: 40px;
                  border: 1px solid #cccc;
                  border-radius: 5px;
                  padding: 10px;
                  border: none;
                  outline:none;
                "
              />
              <!-- <el-input placeholder="请输入内容"></el-input> -->
              <i
                @click="getserach"
                class="el-icon-search"
                style="font-size: 20px; line-height: 40px;margin-right: 10px;"
              ></i>
            </div>
            </div>
        </div>

        <el-table :data="TableData" style="width: 100%">
          <el-table-column
            prop="ID"
            label="ID"
            min-width="100"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="productname"
            label="产品名称"
            width="135"
            align="center"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="productype"
            label="产品类型"
            min-width="100"
          ></el-table-column>
          <el-table-column
            prop="pattern"
            label="服务模式"
            min-width="100"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="spend"
            label="费用"
            min-width="100"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="enterprise_name"
            label="用户名称"
            min-width="100"
            align="center"
          >
          </el-table-column>

          <el-table-column
            prop="enterprise_mobile"
            label="联系方式"
            min-width="100"
            align="center"
          >
          </el-table-column>

          <el-table-column
            prop="name"
            label="服务机构名称"
            min-width="100"
            align="center"
          >
          </el-table-column>

          <el-table-column
            prop="phone"
            label="服务机构电话"
            min-width="100"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="currency"
            label="服务币"
            min-width="100"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="create_time"
            label="下单时间"
            min-width="100"
            align="center"
          >
          </el-table-column>

          <el-table-column
            prop="content"
            label="产品详情"
            min-width="100"
            align="center"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                style="color: #c065e7"
                @click="lookcontent(scope.row)"
                >查看产品详情</el-button
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="content"
            label="操作"
            min-width="100"
            align="center"
          >
            <template>
              <el-button type="text" size="small" style="color: #ff6618"
                >无</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>
    <!-- -->
    <el-pagination
    v-if="TableData.length != 0"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="paginations.page_index"
      :page-sizes="paginations.page_sizes"
      :page-size="paginations.page_size"
      :layout="paginations.layout"
      :total="paginations.total"
    >
    </el-pagination>
  </div>
</template>


<script>
export default {
  data() {
    return {
      activeName: "first",
      search: "",
      options: [],
      paginations: {
        page_index: 1, // 当前位于哪页
        total: 10, // 总数
        page_size: 10, // 1页显示多少条
        page_sizes: [10, 20, 50, 90], //每页显示多少条
        layout: "total, sizes, prev, pager, next, jumper", // 翻页属性
      },
      total: "",
      TableData: [],
      sta: 1,
      allTableData: [],
      ruleForm: {
        region: "",
      },
      rules: {},
    };
  },
  created() {
    // this.productLst();
    this.getcatelist();
    this.getorderlist();
    // this.getordersave();
  },
  methods: {
    // 查看详情
    lookcontent(item) {
      console.log(item,'item')
      this.$router.push({ path: "/serveDetails", query: { productid: item.commissioner_id } });
    },

    amend(ID) {
      this.$prompt("请输入修改服务币数量", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          let data = {
            token: localStorage.eleToken,
            id: ID,
            currency: value,
          };
          this.$post("product_order_upd", data).then((res) => {
            this.$message({
              type: "success",
              message: res.msg,
            });
            this.getorderlist();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消输入",
          });
        });
    },
    look(id) {
      console.log(id, "id");
    },
    orderStatus(item, index) {
      console.log(item, "item", index, "index");
      let data = {
        token: localStorage.eleToken,
        id: item.ID,
        status: 2,
      };
      this.$confirm("是否继续此操作?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post("product_order_status", data).then((res) => {
            console.log(res, "product_order_statusres");

            this.$message({
              type: "success",
              message: res.msg,
            });
            this.getorderlist();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    getserach() {
      this.getorderlist();
    },

    // //订单列表
    // getordersave() {
    //   let data = {
    //     token: localStorage.eleToken,
    //     commissioner_id: "",
    //     service_product_id: "",
    //   };
    //   console.log(data, "data");
    //   this.$post("product_order_save", data).then((res) => {
    //     console.log(res, "product_order_lstres");
    //   });
    // },

    //列表
    getorderlist() {
      let data = {
        token: localStorage.eleToken,
        keyword: this.search,
        pro_cate_id: this.ruleForm.region === "" ? " " : this.ruleForm.region,
        status: this.sta,
        page: this.paginations.page_index,
        limit: this.paginations.page_size,
      };
      console.log(data, "data");
      this.$post("order_commissioner_select", data).then((res) => {
        console.log(res, "product_order_lstres");
        this.allTableData = res.result;
        if (res.count === "") {
          this.paginations.total = 0;
        } else {
          this.paginations.total = res.count;
        }


         //设置默认的分页数据
         if (this.allTableData.length != 0) {
        this.TableData = this.allTableData.filter((item, index) => {
          console.log(item, "item");
          return index < this.paginations.page_size;
        });
      } else {
        this.TableData = [];
      }


      });
    },

    //获取下拉列表
    getcatelist() {
      let data = { token: localStorage.eleToken };
      this.$get("cate_list", data).then((res) => {
        this.options = res.result.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        });
      });
    },
    handleClick(tab, event) {
      console.log(tab, event);
      this.sta = Number(tab.index) + 1;
      this.getorderlist();
      this.getcatelist();
    },
    handleSizeChange(page_size) {
      console.log(page_size, "page_size");
      this.paginations.page_index = 1;
      this.paginations.page_size = page_size;
      this.TableData = this.allTableData.filter((item, index) => {
        return index < page_size;
      });
    },

    // 分页

    handleCurrentChange(page) {
      console.log(page, "handleCurrentChangepage");
      //获取当前页
      let index = this.paginations.page_size * (page - 1);
      //获取数据总数
      let nums = this.paginations.page_size * page;
      //容器
      let tables = [];
      for (let i = index; i < nums; i++) {
        if (this.allTableData[i]) {
          tables.push(this.allTableData[i]);
        }
        this.TableData = tables;
      }
    },

    
  },
};
</script>

<style lang="less" scoped>
::v-deep .el-pagination {
  text-align: center;
  margin-top: 20px;
}
::v-deep .el-aside {
  width: 250px !important;
}
//修改菜单颜色
/deep/.el-tabs__item.is-active {
  color: #ff7129 !important;
}
/deep/.el-tabs__active-bar {
  background-color: #ff7129 !important;
}
/deep/.el-tabs__item:hover {
  color: #ff7129 !important;
}
/deep/.el-form-item{
  margin: auto;
}
</style>
